<template>
  <div style="margin: 4.26667vw">
    <van-button round block type="primary" @click="onConfirmCancel">
      撤回预约
    </van-button>
  </div>
  <van-steps direction="vertical" :active="0"
             active-color="#000009" inactive-color="#000009">
    <van-step
        v-for="item in dataList"
        :key="item.id">
      <p>{{ item.des }}</p>
      <p>[候选人]{{ item.candicateNames }}</p>
      <p>[审批人]{{ item.operateUserName }}</p>
      <p>{{ item.operateTime }}</p>
    </van-step>
  </van-steps>
</template>

<script>
import _ from "lodash";

export default {
  name: "WorkFlowLog",

  components: {},

  props: {
    title: {
      default: '',
      type: String
    }
  },

  emits: [],

  setup() {
  },

  data() {
    return {
      dataList: [],
    };
  },


  computed: {},

  watch: {},

  created() {

  },

  mounted() {
    this.getDataList();
  },

  unmounted() {
  },

  methods: {
    getDataList() {
      var me = this;
      var id = this.$route.params.id;
      if (_.isEmpty(id)) {
        return;
      }
      var option = {
        portName: 'workflow.WFProcess.getProcessLogList',
        data: {processID: id},
        needLoading: false,
        successCallback: (data) => {
          this.dataList.length = 0;
          _.forEach(data.entities, function (e) {
            if (!_.isEmpty(e.candicateNames)) {
              me.dataList.push(e);
            }
          });
        }
      };
      this.$sapi.callPort(option);
    },
    onConfirmCancel() {
      let me = this;
      var id = this.$route.params.id;
      var option = {
        portName: 'workflow.WFProcess.cancelWFProcess',
        data: {processID: id},
        needLoading: false,
        successCallback: (data) => {
          me.$dialog.confirm({
            title: '',
            message: '撤销成功',
          }).then(() => {
            me.$router.go(-1);
          }).catch(() => {
          });
        }
      };
      me.$dialog.confirm({
        title: '',
        message:
            '是否确定撤回',
      }).then(() => {
        me.$sapi.callPort(option);
      }).catch(() => {
        // on cancel
      });
    }
  }
}
</script>

<style scoped>

</style>